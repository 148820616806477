import { ChiroUpDayJsCommon } from '@chiroup/core/constants/stringConstants';
import { clog } from '@chiroup/core/functions/clog';
import dayjs, { isDayjs } from 'dayjs';
import { ReactNode } from 'react';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);

type Props = {
  ts?: any;
  itemTz?: string;
  userTz?: string;
  errorValue?: ReactNode;
  className?: string;
  logWarnings?: boolean;
  noTimezoneIsError?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  title?: string;
  asDateOnly?: boolean;
  format?: string;
  dateString?: string | null;
  utc?: boolean;
};

const componentName = 'TimestampDisplay';

export const TimestampDisplay: React.FC<Props> = ({
  ts,
  itemTz,
  userTz,
  className = '',
  errorValue = <cite className="text-gray-400">-na-</cite>,
  logWarnings = false,
  noTimezoneIsError = false,
  prefix = null,
  suffix = null,
  title = '',
  format = ChiroUpDayJsCommon.format.datetime,
  dateString = null,
  asDateOnly = false,
  utc = false,
}) => {
  if (isDayjs(ts)) {
    ts = ts.valueOf();
  }
  if (dateString) {
    let showDate = dateString;
    try {
      if (dateString.indexOf('/') > -1) {
        ts = new Date(dateString).getTime();
      } else if (dateString.indexOf('-') > -1) {
        ts = new Date(`${dateString} 00:00:00`).getTime(); // Interestingly, ISO dates are adjusted for timezone while mm/dd/yyyy are not.
      }
      showDate = dayjs(ts).format(ChiroUpDayJsCommon.format.date);
    } catch (e) {
      if (logWarnings) {
        clog(`${componentName}: Error parsing date string: ${dateString}`);
      }
      return errorValue;
    }
    return (
      <div className={className} title={title}>
        {prefix}
        {showDate}
        {suffix}
      </div>
    );
  }

  if (!ts) {
    if (logWarnings) clog(`${componentName}: No timestamp value.`);
    return errorValue;
  }

  if (!itemTz) {
    if (logWarnings) clog(`${componentName}: No item timezone.`);
    itemTz = ChiroUpDayJsCommon.defaultTimezone;
    if (noTimezoneIsError) return errorValue;
  }

  if (!userTz) {
    if (logWarnings) clog(`${componentName}: No user timezone.`);
    userTz = ChiroUpDayJsCommon.defaultTimezone;
    if (noTimezoneIsError) return errorValue;
  }

  if (asDateOnly) {
    return (
      <div className={className} title={title}>
        {prefix}
        {ChiroUpDayJsCommon.display.dateWithTz(
          ts,
          itemTz,
          userTz,
          ChiroUpDayJsCommon.format.date,
        )}
        {suffix}
      </div>
    );
  }

  return (
    <div className={className} title={title}>
      {prefix}
      {ChiroUpDayJsCommon.display.datetimeWithTz(ts, itemTz, userTz, format)}
      {suffix}
    </div>
  );
};
