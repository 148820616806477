import dayjs, { Dayjs } from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Input from 'intergalactic/input';
import Select from 'intergalactic/select';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldErrors } from './FieldErrors';
import { generateTimeOptions, getMilitaryTime } from './DateTime';

dayjs.extend(utc);
dayjs.extend(tz);

type Props = {
  label?: string;
  value?: Dayjs;
  onChange: (val: Dayjs) => void;
  timezone?: string;
  errors?: { message?: string };
  // localErrors?: string;
  setFormErrors?: (val: string) => void;
  timeInterval?: number;
  setHasError?: (val: boolean) => void;
  hideError?: boolean;
};

export const TimeSelect: React.FC<Props> = ({
  label,
  value,
  onChange,
  timezone = 'America/New_York',
  errors,
  setFormErrors,
  timeInterval = 15,
  setHasError,
  hideError = false,
}) => {
  const [localTimeValue, setLocalTimeValue] = useState<string>('');
  const [meridiem, setMeridiem] = useState<'am' | 'pm'>('am');
  const [showError, setShowError] = useState(false);
  const [localErrors, setLocalErrors] = useState('');
  useEffect(() => {
    if (value && !localTimeValue?.length) {
      const timeString = value.format('h:mm');
      const meridiem = value.format('a');
      setLocalTimeValue(timeString);
      setMeridiem(meridiem as 'am' | 'pm');
    }
  }, [localTimeValue, value]);

  useEffect(() => {
    if (localTimeValue?.length) {
      const timeRegex = /^(1[0-2]|0?[1-9]):([0-5][0-9])$/;
      if (!timeRegex.test(localTimeValue)) {
        setLocalErrors?.('Invalid time format. Please use HH:MM');
        setFormErrors?.('Invalid time format. Please use HH:MM');
        setHasError?.(true);
      } else {
        setLocalErrors?.('');
        setFormErrors?.('');
        setHasError?.(false);
      }
    }
  }, [localTimeValue, setLocalErrors, setFormErrors, setHasError]);

  const options = useMemo(
    () => generateTimeOptions('00:00', '23:45', timeInterval),
    [timeInterval],
  );

  const handleTimeChange = (val: string) => {
    const am = val.includes('am');
    const timeOnly = val.replace(/(AM|PM)$/i, '').trim();
    setLocalTimeValue(timeOnly);
    const timeRegex = /^(1[0-2]|0?[1-9]):[0-5][0-9]$/;
    // If the value comes from dropdown, update meridiem
    if (am) {
      setMeridiem('am');
    } else {
      setMeridiem('pm');
    }

    setShowError(false);

    const [hours, minutes] = timeOnly.split(':').map(Number);

    const militaryHours =
      !am && hours !== 12 ? hours + 12 : am && hours === 12 ? 0 : hours;
    if (!timeRegex.test(timeOnly) && val.length > 0) {
      setLocalErrors?.('Invalid time format. Please use HH:MM');
      setFormErrors?.('Invalid time format. Please use HH:MM');
      setShowError(true);
    } else {
      setLocalErrors?.('');
      setFormErrors?.('');
      setShowError(false);

      onChange(
        dayjs()
          .tz(timezone)
          .hour(militaryHours)
          .minute(minutes || 0)
          .second(0),
      );
    }
  };

  const toggleMeridiem = () => {
    const newMeridiem = meridiem === 'am' ? 'pm' : 'am';
    setMeridiem(newMeridiem);
    if (!localTimeValue) return;
    const [hours, minutes] = localTimeValue.split(':').map(Number);

    const militaryHours =
      newMeridiem === 'pm' && hours !== 12
        ? hours + 12
        : newMeridiem === 'am' && hours === 12
          ? 0
          : hours;

    onChange(
      dayjs()
        .tz(timezone)
        .hour(militaryHours)
        .minute(minutes || 0)
        .second(0),
    );
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const indexOfColon = value.indexOf(':');
    if (indexOfColon === 0 || indexOfColon === value.length - 1) {
      setLocalErrors?.('Invalid time format. Please use HH:MM');
      setFormErrors?.('Invalid time format. Please use HH:MM');
      setShowError(true);
      return;
    }

    if (!value) {
      setLocalErrors?.('Time is required');
      setFormErrors?.('Time is required');
      setShowError(true);
      return;
    }

    setLocalErrors?.('');
    setFormErrors?.('');
    setShowError(false);
  };

  return (
    <div>
      <div className="flex flex-col flex-wrap">
        {!!label && (
          <p className="mb-1 block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2 dark:text-darkGray-200">
            {label}
          </p>
        )}
        <div className="flex items-center gap-2  dark:border-darkGray-700 border-r-5">
          <Select
            interaction="focus"
            size="l"
            onChange={handleTimeChange}
            value={localTimeValue}
            className=""
          >
            <Select.Trigger tag={Input}>
              {() => (
                <Input.Value
                  value={localTimeValue}
                  className={[
                    'block w-full border-gray-300 rounded-md transition duration-150 ease-in-out text-sm sm:leading-5 dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200 focus:ring-2 !border !border-solid',
                    errors
                      ? 'border-red-500 text-red-900 placeholder-red-500 focus:border-red-500 focus:ring-red-500 outline-none'
                      : 'focus:border-primary-500 focus:ring-primary-500 outline-none',
                  ].join(' ')}
                  style={{
                    boxShadow: 'none !important',
                  }}
                  placeholder="HH:MM"
                  onChange={(val: string) => {
                    if (/[A-Za-z]/.test(val)) {
                      return;
                    }
                    let [hours, minutes] = val.split(':');
                    if (Number(hours) > 12) {
                      hours = String(12);
                    }
                    if (Number(minutes) > 60) {
                      minutes = String(59);
                    }
                    val = `${hours ? hours : ''}:${minutes ? minutes : ''}`;

                    handleTimeChange(val);
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Backspace') {
                      e.preventDefault();
                      const input = e.target as HTMLInputElement;
                      const cursorPosition = input.selectionStart || 0;
                      const value = input.value;

                      if (value[cursorPosition - 1] === ':') {
                        setTimeout(() => {
                          input.setSelectionRange(
                            cursorPosition - 1,
                            cursorPosition - 1,
                          );
                        }, 0);
                        return;
                      }

                      let newValue =
                        value.slice(0, cursorPosition - 1) +
                        value.slice(cursorPosition);

                      //in case the user highlights the whole text and hits delete..
                      const newValueColonCount = newValue.split(':').length - 1;
                      if (newValueColonCount > 1) {
                        newValue = value;
                      }
                      setLocalTimeValue(newValue);

                      setTimeout(() => {
                        input.setSelectionRange(
                          cursorPosition - 1,
                          cursorPosition - 1,
                        );
                      }, 0);

                      return;
                    }

                    if (e.key === 'Enter') {
                      const fullTime = `${localTimeValue}${meridiem}`;
                      const neededTime = getMilitaryTime(fullTime);
                      if (neededTime) {
                        const [hours, minutes] = neededTime
                          .split(':')
                          .map(Number);
                        onChange(
                          dayjs()
                            .tz(timezone)
                            .hour(hours)
                            .minute(minutes)
                            .second(0),
                        );
                      }
                      setShowError(true);
                    }
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                    if (localErrors || errors?.message) {
                      setShowError(true);
                    }
                  }}
                />
              )}
            </Select.Trigger>
            <Select.Menu>
              {options.map((option) => (
                <Select.Option
                  value={option.text}
                  key={option.value}
                  className={`${
                    option.text === localTimeValue ? 'selected-option' : ''
                  }`}
                >
                  {option.text}
                </Select.Option>
              ))}
            </Select.Menu>
          </Select>
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleMeridiem();
            }}
            className="-ml-1 px-3 py-2 text-sm font-medium text-gray-700 bg-gray-200 border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none dark:bg-darkGray-800 dark:text-darkGray-200 dark:border-darkGray-600 dark:hover:bg-darkGray-70 outline-none ring-0 focus:ring-0"
          >
            {meridiem.toUpperCase()}
          </button>
        </div>
      </div>
      {showError && (localErrors || errors?.message) && !hideError && (
        <FieldErrors
          errors={{ message: localErrors || errors?.message || '' }}
        />
      )}
    </div>
  );
};

export default TimeSelect;
